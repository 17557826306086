import React, { useContext, useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import {
  Atletas_start,
  Competition,
  StartList,
  ids,
} from "src/components/interfaces/Competition";
import { Figura } from "src/components/interfaces/Figura";
import { CoachCard } from "src/components/interfaces/CoachCard";
import { GlobalContext } from "src/contexts/GlobalContext";
import { useHistory } from "react-router-dom";
const url = process.env.REACT_APP_API_URL;
export const AdminPage: React.FC = () => {
  const history = useHistory();
  const useGlobal = () => useContext(GlobalContext);
  const { userInfo } = useGlobal();
  const [competitionData, setCompetitionData] = useState<Competition>({
    _id: undefined,
    Nome: "",
    Data: "",
    Limite_inscricao: "",
    Ordem: [{ Tipo: "", Categoria: "", Resultado: false }],
    Figuras: [{ Nome: "", Valor: 0, Category: "INF" }],
    StartList: [],
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    JuizesCoachCard:5,
    JuizesFiguras:5,
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");

  useEffect(() => {
    if (_id && _id !== undefined && _id !== "") {
      fetch(`${url}/competitions/${_id}`)
        .then((response) => response.json())
        .then((competitionData: Competition) => {
          setCompetitionData(competitionData);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);
  function convertToISODate(dateString: string): string {
    if (!dateString) return "";
    const [month, day, year] = dateString.split("/");
    if (!month || !day || !year) return dateString;
    const paddedMonth = month.padStart
      ? month.padStart(2, "0")
      : ("0" + month).slice(-2);
    const paddedDay = day.padStart
      ? day.padStart(2, "0")
      : ("0" + day).slice(-2);
    const isoDate = `${year}-${paddedMonth}-${paddedDay}`;
    return isoDate;
  }
  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCompetitionData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle Ordem array changes
  const handleOrdemChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCompetitionData((prevData) => {
      const updatedOrdem = [...prevData.Ordem];
      updatedOrdem[index] = { ...updatedOrdem[index], [name]: value };
      return { ...prevData, Ordem: updatedOrdem };
    });
  };

  // Handle Figuras array changes
  const handleFigurasChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCompetitionData((prevData) => {
      const updatedFiguras = [...prevData.Figuras];
      updatedFiguras[index] = { ...updatedFiguras[index], [name]: value };
      return { ...prevData, Figuras: updatedFiguras };
    });
  };
  const shuffle = (array: Atletas_start[]) => {
    return array.sort(() => Math.random() - 0.5);
  };
  const getStartList = () => {
    if (userInfo.role !== "admin") return;
    let startlist: StartList[] = [];
    const request = `${url}/coachcard`;
    const requestFig = `${url}/figura`;
    let coachCards: CoachCard[] = [];
    let figuras: Figura[] = [];
    fetch(request)
      .then((response) => response.json())
      .then((coachCardData: CoachCard[]) => {
        coachCards = coachCardData.filter(
          (c) => c.Competicao.toString() === competitionData._id?.toString()
        );
      })
      .then(() => {
        fetch(requestFig)
          .then((response) => response.json())
          .then((figuraData: Figura[]) => {
            figuras = figuraData.filter(
              (c) => {console.log(c.Competicao, competitionData._id)
                return c.Competicao.toString() === competitionData._id?.toString()}
            );
            console.log(figuras)
          })
          .then(() => generateStartList())
          .then(() => {
            const newcomp: Competition = {
              ...competitionData,
              StartList: startlist,
            };
            setCompetitionData(newcomp);
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err.message);
      });

    function generateStartList() {
      competitionData.Ordem.forEach((x, i) => {
        let startlistelement: StartList = {
          Ordem: i + 1,
          Categoria: x.Categoria,
          Prova: x.Tipo,
          Atletas_start: [],
        };
        if (x.Tipo === "Figuras") {
          const atletasStart: Atletas_start[] = [];
          figuras
            .filter((f) => f.Categoria === x.Categoria)
            .forEach((f) => {
              console.log("f",f)
              f.Atletas.forEach((a, ind) => {
                let atleta: ids = {
                  Numero: (1).toString(),
                  Name: a.Nome,
                  Team: f.Clube,
                  License: a.Licenca,
                  Year: a.Ano,
                };
                let entry: Atletas_start = {
                  Numero: 1,
                  Atletas: [atleta],
                };
                console.log(entry)
                atletasStart.push(entry);
              });
            });
          const a = shuffle(atletasStart);
          startlistelement.Atletas_start = a;
          console.log(startlistelement)
        } else {
          coachCards
            .filter((c) => x.Categoria === c.Categoria && x.Tipo === c.Esquema)
            .forEach((co, i) => {
              let entry: Atletas_start = {
                Numero: i + 1,
                Atletas: [],
              };
              co.Atletas.forEach((a, ind) => {
                let atleta: ids = {
                  Numero: (ind + 1).toString(),
                  Name: a.Nome,
                  Team: co.Clube,
                  License: a.Licenca,
                  Year: a.Ano,
                };
                entry.Atletas.push(atleta);
              });
              co.Reservas.filter((r) => r.Nome !== "").forEach((a, ind) => {
                let atleta: ids = {
                  Numero: "R" + (ind + 1).toString(),
                  Name: a.Nome,
                  Team: co.Clube,
                  License: a.Licenca,
                  Year: a.Ano,
                };
                entry.Atletas.push(atleta);
              });
              startlistelement.Atletas_start.push(entry);
            });
        }
        if (startlistelement.Atletas_start.length > 0) {
          startlist.push(startlistelement);
        }
      });
    }
  };
  function removeProva(index: number): void {
    setCompetitionData({
      ...competitionData,
      Ordem: competitionData.Ordem.filter((x, i) => i !== index),
    });
  }
  function removeFigura(index: number): void {
    setCompetitionData({
      ...competitionData,
      Figuras: competitionData.Figuras.filter((x, i) => i !== index),
    });
  }
  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    const newcomp: Competition = competitionData;

    e.preventDefault();
    delete newcomp["_id"];
    const json = JSON.stringify(newcomp);
    if (_id && _id !== "") {
      fetch(`${url}/competitions/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setCompetitionData({
            ...newcomp,
            _id: data.insertedId,
          });
          alert("Competição gravado com sucesso");
          history.push("/competition");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // Do something with the competitionData, e.g., send it to a server
      console.log("Form submitted:", competitionData);
    } else {
      fetch(`${url}/competitions/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setCompetitionData({
            ...newcomp,
            _id: data.insertedId,
          });
          alert("Competição gravado com sucesso");
          history.push("/competition");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // Do something with the competitionData, e.g., send it to a server
      console.log("Form submitted:", competitionData);
    }
  };

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Admin Page
        </h1>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <label style={{ width: "50%" }}>
              Nome:
              <input
                type="text"
                name="Nome"
                value={competitionData.Nome}
                onChange={handleInputChange}
              />
            </label>

            <label style={{ width: "25%" }}>
              Data:
              <input
                type="date"
                name="Data"
                value={convertToISODate(competitionData.Data)}
                onChange={handleInputChange}
              />
            </label>

            <label style={{ width: "25%" }}>
              Limite de inscrições:
              <input
                type="date"
                name="Limite_inscricao"
                value={convertToISODate(competitionData.Limite_inscricao)}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <br />
          <button type="button" onClick={() => getStartList()}>
            Gerar StartList
          </button>
          <br />
          <h3>Ordem de provas</h3>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Ordem</th>
                <th>Prova</th>
                <th>Categoria</th>
                <th>Resultado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {competitionData.Ordem.map((order, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <label>
                      <select
                        name="Tipo"
                        value={order.Tipo}
                        onChange={(e) => handleOrdemChange(index, e)}
                      >
                        <option value="none">Selecione um esquema</option>
                        <option key="Figuras" value="Figuras">
                          FIGURAS
                        </option>
                        <option key="SOLO TECNICO" value="SOLO TECNICO">
                          SOLO TECNICO
                        </option>
                        <option key="DUETO TECNICO" value="DUETO TECNICO">
                          DUETO TECNICO
                        </option>
                        <option
                          key="DUETO MISTO TECNICO"
                          value="DUETO MISTO TECNICO"
                        >
                          DUETO MISTO TECNICO
                        </option>
                        <option key="EQUIPA TECNICA" value="EQUIPA TECNICA">
                          EQUIPA TECNICA
                        </option>
                        <option key="SOLO LIVRE" value="SOLO LIVRE">
                          SOLO LIVRE
                        </option>
                        <option key="DUETO LIVRE" value="DUETO LIVRE">
                          DUETO LIVRE
                        </option>
                        <option
                          key="DUETO MISTO LIVRE"
                          value="DUETO MISTO LIVRE"
                        >
                          DUETO MISTO LIVRE
                        </option>
                        <option key="EQUIPA LIVRE" value="EQUIPA LIVRE">
                          EQUIPA LIVRE
                        </option>
                        <option key="COMBINADO" value="COMBINADO">
                          COMBINADO
                        </option>
                      </select>
                    </label>
                  </td>
                  <td>
                    <label>
                      <select
                        name="Categoria"
                        value={order.Categoria}
                        onChange={(e) => handleOrdemChange(index, e as any)}
                      >
                        <option key="INF" value="INF">
                          INFANTIL
                        </option>
                        <option key="JUV" value="JUV">
                          JUVENIL
                        </option>
                        <option key="JUN" value="JUN">
                          JUNIOR
                        </option>
                        <option key="ABS" value="ABS">
                          ABSOLUTOS
                        </option>
                        <option key="MAS" value="MAS">
                          MASTERS
                        </option>
                      </select>
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        name="Resultado"
                        checked={order.Resultado}
                        onChange={(e) => handleOrdemChange(index, e as any)}
                      />
                    </label>
                  </td>
                  <td>
                    <button type="button" onClick={() => removeProva(index)}>
                      Remover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={() =>
              setCompetitionData((prevData) => ({
                ...prevData,
                Ordem: [
                  ...prevData.Ordem,
                  { Tipo: "", Categoria: "", Resultado: false },
                ],
              }))
            }
          >
            Add Order
          </button>

          <h3>Figuras</h3>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Valor</th>
                <th>Categoria</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {competitionData.Figuras.map((figura, index) => (
                <tr key={index}>
                  <td>
                    <label>
                      <input
                        type="text"
                        name="Nome"
                        value={figura.Nome}
                        onChange={(e) => handleFigurasChange(index, e)}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type="number"
                        name="Valor"
                        value={figura.Valor}
                        onChange={(e) => handleFigurasChange(index, e)}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <select
                        name="Category"
                        value={figura.Category}
                        onChange={(e) => handleFigurasChange(index, e as any)}
                      >
                        <option value="INF">INFANTIL</option>
                        <option value="JUV">JUVENIL</option>
                      </select>
                    </label>
                  </td>
                  <td>
                    <button type="button" onClick={() => removeFigura(index)}>
                      Remover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={() =>
              setCompetitionData((prevData) => ({
                ...prevData,
                Figuras: [
                  ...prevData.Figuras,
                  { Nome: "", Valor: 0, Category: "INF" },
                ],
              }))
            }
          >
            Adicionar Figura
          </button>
          <br />
          <br />
          <br />
          <h3>Número de juizes</h3>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <label style={{ width: "25%" }}>
              CoachCard:
              <input
                type="number"
                name="JuizesCoachCard"
                value={competitionData.JuizesCoachCard}
                onChange={handleInputChange}
                max={7}
                min={5}
              />
            </label>

            <label style={{ width: "25%" }}>
              Figuaras:
              <input
                type="number"
                name="JuizesFiguras"
                value={competitionData.JuizesFiguras}
                onChange={handleInputChange}
                max={7}
                min={5}
              />
            </label>
          </div>
          <br />
          <br />
          <br />
          <button type="submit">Gravar</button>
        </form>
      </div>
    </PageLayout>
  );
};
