import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { ProtectedRoute } from "./components/protected-route";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { CompetitionPage } from "./pages/Competitions/competition-page";
import CreateCoachCard from "./pages/CoachCard/CoachCard";
import { useGlobal } from "./contexts/GlobalContext";
import CoachCardTable from "./pages/CoachCard/ListCoachCard";
import ResultCoachCard from "./pages/CoachCard/ResultCoachCard";
import RefCoachCard from "./pages/CoachCard/RefCoachCard";
import CreateFiguras from "./pages/Figuras/CreateFiguras";
import FigurasTable from "./pages/Figuras/ListFiguras";
import StartListComponent from "./pages/StartList/StartList";
import ResultFiguras from "./pages/Figuras/ResultFiguras";
import PontCoachCard from "./pages/CoachCard/PontCoachCard";
import ResultListComponent from "./pages/ResultList/ResultList";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const { userInfo, setuserInfo } = useGlobal();


    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const accessToken = await getAccessTokenSilently();

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });

        const { user_metadata } = await metadataResponse.json();
        setuserInfo({ role: user_metadata.role, clube: user_metadata.clube });
      } catch (e) {
        console.log(e);
      }
    };
    
  
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
if(userInfo.role === '' || userInfo.clube === '') {
  getUserMetadata();
}
  
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <ProtectedRoute path="/profile" component={ProfilePage} />
      <Route path="/public" component={PublicPage} />
      <Route path="/competition" component={CompetitionPage} />
      <ProtectedRoute path="/coachCard" component={CreateCoachCard} />
      <ProtectedRoute path="/listCoachCard" component={CoachCardTable} /> 
      <ProtectedRoute path="/resultCoachCard" component={ResultCoachCard} />
      <ProtectedRoute path="/refCoachCard" component={RefCoachCard} />
      <ProtectedRoute path="/pontCoachCard" component={PontCoachCard} />
      <ProtectedRoute path="/figura" component={CreateFiguras} />
      <ProtectedRoute path="/listFiguras" component={FigurasTable} /> 
      <ProtectedRoute path="/resultFiguras" component={ResultFiguras} /> 
      <ProtectedRoute path="/protected" component={ProtectedPage} />
      <ProtectedRoute path="/admin" component={AdminPage} />
      <Route path="/startList" component={StartListComponent} />
      <Route path="/resultList" component={ResultListComponent} />
      <Route path="/callback" component={CallbackPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};
