import React, { useEffect, useState } from "react";
import { CoachCard } from "src/components/interfaces/CoachCard";
import "./PontCoachCard.css";
import { PageLayout } from "src/components/page-layout";
import { useGlobal } from "src/contexts/GlobalContext";
import html2pdf from "html2pdf.js";
const PontCoachCard: React.FC = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  useEffect(() => {
    if (_id && _id !== "") {
      fetch(`${url}/coachCard/${_id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: CoachCard) => {
          console.log(data);
          if (data) {
            setCoachCardData(data);
          }
        });
    }
  }, []);
  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();
  const [coachCardData, setCoachCardData] = useState<CoachCard>({
    _id: "",
    Competicao: "",
    Categoria: "",
    Esquema: "",
    Clube: userInfo.clube,
    Tema_esquema: "",
    Atletas: [],
    Reservas: [],
    Elementos: [],
    State: "Rascunho",
    Total_declarado: 0,
  });

  const generatePdf = async () => {
    const element = document.getElementById(_id + "");
    const options = {
      filename: `PCC_${coachCardData.Clube}_${coachCardData.Categoria}_${coachCardData.Esquema}`,
      pagebreak: { mode: "css", before: ".page-break" },
      margin: 10,
      jsPDF: {
        format: "a6",
      },
    };
    await html2pdf().from(element).set(options).save();
  };
  return (
    <PageLayout>
      <div className="pont-body">
        <fieldset id={_id + ""} disabled>
          <h5>{coachCardData.Competicao}</h5>
          <div className="pont-form-container">
            <div className="pont-form-group">
              <label className="pont-label">Nº Apresentação:</label>
              <input
                className="pont-input-text"
                type="text"
                value=""
                disabled
              />
            </div>
            <div className="pont-form-group">
              <label className="pont-label">Data:</label>
              <input
                className="pont-input-text"
                type="text"
                value=""
                disabled
              />
            </div>
          </div>
          <div className="pont-form-container">
            <div className="pont-form-group">
              <label className="pont-label">Categoria:</label>
              <input
                className="pont-input-text"
                type="text"
                value={coachCardData.Categoria}
                disabled
              />
            </div>
            <div className="pont-form-group">
              <label className="pont-label">Prova/Esquema:</label>
              <input
                className="pont-input-text"
                type="text"
                value={coachCardData.Esquema}
                disabled
              />
            </div>
            <div className="pont-form-group">
              <label className="pont-label">Clube:</label>
              <input
                className="pont-input-text"
                type="text"
                value={coachCardData.Clube}
                disabled
              />
            </div>
          </div>
          {coachCardData.Elementos.length > 0 && (
            <div>
              <h6>Elementos:</h6>
              <div className="pont-table-container">
                <table className="pont-table">
                  <tbody>
                    {coachCardData.Elementos.filter(
                      (e) => e.Elemento !== "TRA"
                    ).map((elemento, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            className="pont-input-text"
                            type="text"
                            value={
                              index +
                              1 +
                              ". " +
                              (elemento.Elemento === "TRE"
                                ? elemento.Dificuldade_declarada[0].Codigo_cc
                                : elemento.Elemento) +
                              " " +
                              (elemento.Grupo !== "none" ? elemento.Grupo : "")
                            }
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="pont-input-text"
                            type="text"
                            value={""}
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Juiz nº:</td>
                      <td>Assinatura:</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </fieldset>
      </div>
      <div>
        <button className="button" onClick={() => generatePdf()}>
          Gravar Pdf
        </button>
      </div>
    </PageLayout>
  );
};

export default PontCoachCard;
