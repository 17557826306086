import React, { useEffect, useState } from "react";
import {
  CoachCard,
  Atletas,
  Elementos,
  Coeficientes,
} from "src/components/interfaces/CoachCard";
import "./CoachCard.css";
import { PageLayout } from "src/components/page-layout";
import {
  bonus,
  coefHibridas,
  duetoMTRE,
  duetoTRE,
  equipaTRE,
  soloTRE,
} from "./Codes";
import { useGlobal } from "src/contexts/GlobalContext";
import { useHistory } from "react-router-dom";
import { Competition } from "src/components/interfaces/Competition";
const CreateCoachCard: React.FC = () => {
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();
  const [competition, setCompetition] = useState<Competition>({
    Nome: "",
    Data: "",
    Limite_inscricao: "",
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    StartList: [],
    _id: undefined,
    Ordem: [],
    Figuras: [],
    JuizesCoachCard: undefined,
    JuizesFiguras:undefined
  });
  const [coachCardData, setCoachCardData] = useState<CoachCard>({
    _id: "",
    Competicao: "",
    Categoria: "",
    Esquema: "",
    Clube: userInfo.clube,
    Tema_esquema: "",
    Atletas: [],
    Reservas: [],
    Elementos: [],
    State: "Rascunho",
    Total_declarado: 0,
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  let _compId = params.get("compId");
  const elementosMater: Elementos[] = [
    {
      Tempo_inicial: "0",
      Tempo_final: "0",
      Elemento: "FishTail",
      Ordem: "1",
      Grupo: "none",
      Nota_base: 1.6,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 1.6,
    },
    {
      Tempo_inicial: "0",
      Tempo_final: "0",
      Elemento: "Split Position",
      Ordem: "2",
      Grupo: "none",
      Nota_base: 1.3,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 1.3,
    },
    {
      Tempo_inicial: "0",
      Tempo_final: "0",
      Elemento: "Spinning 180º",
      Ordem: "3",
      Grupo: "none",
      Nota_base: 1.1,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 1.1,
    },
    {
      Tempo_inicial: "0",
      Tempo_final: "0",
      Elemento: "Travelling Ballet",
      Ordem: "4",
      Grupo: "none",
      Nota_base: 1.3,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 1.3,
    },
    {
      Tempo_inicial: "0",
      Tempo_final: "0",
      Elemento: "Barracuda",
      Ordem: "5",
      Grupo: "none",
      Nota_base: 1.6,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 1.6,
    },
  ];
  useEffect(() => {
    console.log(_id)
    if (_id && _id !== "") {
      fetch(`${url}/coachCard/${_id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: CoachCard) => {
          fetch(`${url}/competitions/${data.Competicao}`)
          .then((response) => response.json())
          .then((competitionData: Competition) => {
            setCompetition(competitionData);
              setCoachCardData({ ...data, Competicao: competitionData._id || "error" });
          })
          .catch((err) => {
            console.log(err.message);
          });
        });
    }
    if (_compId && _compId !== undefined && _compId !== "") {
      fetch(`${url}/competitions/${_compId}`)
          .then((response) => response.json())
          .then((competitionData: Competition) => {
            setCompetition(competitionData);
              setCoachCardData({ ...coachCardData, Competicao: competitionData._id || "error" });
          })
          .catch((err) => {
            console.log(err.message);
          });
    }
  }, []);

  const addAtleta = () => {
    const newAtleta: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
    setCoachCardData({
      ...coachCardData,
      Atletas: [...coachCardData.Atletas, newAtleta],
    });
  };
  const addReserva = () => {
    const newAtleta: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
    setCoachCardData({
      ...coachCardData,
      Reservas: [...coachCardData.Atletas, newAtleta],
    });
  };
  const getCoefValue = (
    name: string,
    elemento: string,
    esquema: string
  ): number => {
    let data: Coeficientes[] = [];
    if (elemento === "TRE") {
      switch (esquema) {
        case "SOLO TECNICO":
          data = soloTRE;
          break;
        case "DUETO MISTO TECNICO":
          data = duetoMTRE;
          break;
        case "DUETO TECNICO":
          data = duetoTRE;
          break;
        case "EQUIPA TECNICA":
          data = equipaTRE;
          break;
        default:
          data = [];
          break;
      }
    } else if (elemento === "HIB") {
      data = coefHibridas;
    }
    return data.filter((x) => x.Codigo_cc === name)[0].Valor;
  };
  const addElemento = () => {
    const tempo_ini =
      coachCardData.Elementos.length === 0
        ? "0"
        : coachCardData.Elementos[coachCardData.Elementos.length - 1]
            .Tempo_final;
    const newElemento: Elementos = {
      Tempo_inicial: tempo_ini,
      Tempo_final: "",
      Elemento: "",
      Ordem: "",
      Grupo: "",
      Nota_base: 0,
      Dificuldade_declarada: [],
      Bonus: [],
      Total: 0,
    };
    setCoachCardData({
      ...coachCardData,
      Elementos: [...coachCardData.Elementos, newElemento],
    });
  };

  const addCoeficiente = (
    index: number,
    type: "Dificuldade_declarada" | "Bonus"
  ) => {
    const newCoeficiente: Coeficientes = { Codigo_cc: "", Valor: 0 };
    const updatedData = { ...coachCardData };
    updatedData.Elementos[index][type].push(newCoeficiente);
    setCoachCardData(updatedData);
  };

  const getCoeficientData = (elemento: string, esquema: string) => {
    let data: Coeficientes[] = [];
    if (elemento === "TRE") {
      switch (esquema) {
        case "SOLO TECNICO":
          data = soloTRE;
          break;
        case "DUETO MISTO TECNICO":
          data = duetoMTRE;
          break;
        case "DUETO TECNICO":
          data = duetoTRE;
          break;
        case "EQUIPA TECNICA":
          data = equipaTRE;
          break;
        default:
          data = [];
          break;
      }
    } else if (elemento === "HIB") {
      data = coefHibridas;
    }
    return data;
  };
  const totalElemento = (index: number): number => {
    const sum =
      coachCardData.Elementos[index].Dificuldade_declarada.reduce(
        (accumulator, object) => {
          return accumulator + object.Valor;
        },
        0
      ) +
      coachCardData.Elementos[index].Bonus.reduce((accumulator, object) => {
        return accumulator + object.Valor;
      }, 0);
    return +sum.toFixed(4);
  };
  const totalDeclarado = (): number => {
    const sum = coachCardData.Elementos.reduce((accumulator, object) => {
      return accumulator + object.Total;
    }, 0);
    return +sum.toFixed(4);
  };
  const saveCoachCard = (state: "Rascunho" | "Submetido" | "Verificado") => {
    setLoad(true);
    coachCardData.State = state;
    delete coachCardData["_id"];
    const json = JSON.stringify(coachCardData);
    console.log(_id)
    if (_id && _id !== "") {
        fetch(`${url}/coachcard/${_id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: json,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setLoad(false);
            alert("Coach card gravado com sucesso");
            history.push("/listCoachCard");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    }else{
      console.log(json);
      fetch(`${url}/coachcard/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setLoad(false);
          alert("Coach card gravado com sucesso");
          history.push("/listCoachCard");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  function removeElemento(index: number): void {
    if (index !== coachCardData.Elementos.length - 1) {
      window.alert("Só pode ser removido o último elemento");
      return;
    }
    coachCardData.Elementos.filter((x, i) => i !== index);
    setCoachCardData({
      ...coachCardData,
      Elementos: coachCardData.Elementos.filter((x, i) => i !== index),
    });
  }
  function uniqByReduce(array: string[]): string[] {
    return array.reduce((acc: string[], cur: string) => {
      if (!acc.includes(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }
  return (
    <PageLayout>
      <fieldset
        disabled={
          coachCardData.State === "Submetido" ||
          coachCardData.State === "Verificado"
        }
      >
        <h2>Criar Coach Card</h2>
        <div className="form-container">
          <div className="form-group">
            <label>Categoria:</label>
            <select
              value={coachCardData.Categoria}
              onChange={(e) =>
                setCoachCardData({
                  ...coachCardData,
                  Categoria: e.target.value,
                  Elementos: e.target.value === "MAS" ? elementosMater : [],
                })
              }
            >
              <option value="none">Selecione um elemento</option>
              <option key="INF" value="INF">
                INFANTIL
              </option>
              <option key="JUV" value="JUV">
                JUVENIL
              </option>
              <option key="JUN" value="JUN">
                JUNIOR
              </option>
              <option key="ABS" value="ABS">
                ABSOLUTOS
              </option>
              <option key="MAS" value="MAS">
                MASTERS
              </option>
            </select>
          </div>
          <div className="form-group">
            <label>Prova/Esquema:</label>
            <select
              value={coachCardData.Esquema}
              onChange={(e) => {
                let atletas: Atletas[] = [];
                let reservas: Atletas[] = [];
                const newAtleta: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
                const newAtleta2: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
                const newReserva: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
                const newReserva2: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
                switch (e.target.value) {
                  case "EQUIPA TECNICA":
                  case "EQUIPA LIVRE":
                  case "COMBINADO":
                    break;
                  case "SOLO TECNICO":
                  case "SOLO LIVRE":
                    atletas.push(newAtleta);
                    reservas.push(newReserva);
                    break;
                  case "DUETO TECNICO":
                  case "DUETO MISTO TECNICO":
                  case "DUETO LIVRE":
                  case "DUETO MISTO LIVRE":
                    atletas[0] = newAtleta;
                    atletas[1] = newAtleta2;
                    reservas[0] = newReserva;
                    reservas[1] = newReserva2;
                    break;
                }
                setCoachCardData({
                  ...coachCardData,
                  Esquema: e.target.value,
                  Atletas: atletas,
                  Reservas: reservas,
                });
              }}
            >
              <option value="none">Selecione um esquema</option>
              {uniqByReduce(
                competition.Ordem.map((a) => a.Tipo).filter(
                  (a) => a.toLowerCase() !== "figuras"
                )
              ).map((esquema, index) => (
                <option key={esquema} value={esquema}>
                  {esquema}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Clube:</label>
            <input type="text" value={coachCardData.Clube} disabled />
          </div>
          <div className="form-group">
            <label>Tema do Esquema:</label>
            <input
              type="text"
              value={coachCardData.Tema_esquema}
              onChange={(e) => {
                setCoachCardData({
                  ...coachCardData,
                  Tema_esquema: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div>
          <button
            hidden={
              !(
                coachCardData.Categoria &&
                coachCardData.Esquema &&
                coachCardData.Tema_esquema
              ) ||
              coachCardData.State === "Submetido" ||
              coachCardData.State === "Verificado" ||
              (coachCardData.Esquema !== "EQUIPA TECNICA" &&
                coachCardData.Esquema !== "EQUIPA LIVRE" &&
                coachCardData.Esquema !== "COMBINADO")
            }
            className={`button ${
              !(
                coachCardData.Categoria &&
                coachCardData.Esquema &&
                coachCardData.Tema_esquema
              )
                ? "disabled-button"
                : ""
            }`}
            onClick={addAtleta}
          >
            Adicionar Atleta
          </button>
        </div>
        <div>
          {coachCardData.Atletas.length > 0 && (
            <div>
              <h3>Atletas:</h3>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Licenca</th>
                      <th>Nome</th>
                      <th>Ano</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachCardData.Atletas.map((atleta, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="number"
                            value={atleta.Licenca.toString()}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Atletas[index].Licenca =
                                +e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={atleta.Nome}
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Atletas[index].Nome = e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={atleta.Ano}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Atletas[index].Ano = +e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div>
          <button
            hidden={
              !(
                coachCardData.Categoria &&
                coachCardData.Esquema &&
                coachCardData.Tema_esquema
              ) ||
              coachCardData.State === "Submetido" ||
              coachCardData.State === "Verificado" ||
              (coachCardData.Esquema !== "EQUIPA TECNICA" &&
                coachCardData.Esquema !== "EQUIPA LIVRE" &&
                coachCardData.Esquema !== "COMBINADO")
            }
            className={`button ${
              !(
                coachCardData.Categoria &&
                coachCardData.Esquema &&
                coachCardData.Tema_esquema
              )
                ? "disabled-button"
                : ""
            }`}
            onClick={addReserva}
          >
            Adicionar Reserva
          </button>
        </div>
        <div>
          {coachCardData.Reservas.length > 0 && (
            <div>
              <h3>Reservas:</h3>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Licenca</th>
                      <th>Nome</th>
                      <th>Ano</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachCardData.Reservas.map((atleta, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="number"
                            value={atleta.Licenca.toString()}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Reservas[index].Licenca =
                                +e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={atleta.Nome}
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Reservas[index].Nome = e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={atleta.Ano}
                            onChange={(e) => {
                              const updatedData = { ...coachCardData };
                              updatedData.Reservas[index].Ano = +e.target.value;
                              setCoachCardData(updatedData);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div>
          <button
            className={`button ${
              !(
                coachCardData.Categoria &&
                coachCardData.Esquema &&
                coachCardData.Tema_esquema
              )
                ? "disabled-button"
                : ""
            }`}
            hidden={
              coachCardData.Atletas.length === 0 ||
              coachCardData.State === "Submetido" ||
              coachCardData.State === "Verificado" ||
              coachCardData.Categoria === "MAS"
            }
            onClick={addElemento}
          >
            Adicionar Elemento
          </button>
        </div>
        {coachCardData.Elementos.length > 0 && (
          <div>
            <h3>Elementos:</h3>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Tempo Inicial</th>
                    <th>Tempo Final</th>
                    <th>Elemento</th>
                    <th>Ordem</th>
                    <th>Grupo</th>
                    <th>Nota Base</th>
                    <th hidden={coachCardData.Categoria === "MAS"}>
                      Dificuldade Declarada
                    </th>
                    <th hidden={coachCardData.Categoria === "MAS"}>Bonus</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {coachCardData.Elementos.map((elemento, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={elemento.Tempo_inicial}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={elemento.Tempo_final}
                          onChange={(e) => {
                            const updatedData = { ...coachCardData };
                            updatedData.Elementos[index].Tempo_final =
                              e.target.value;
                            if (index + 1 !== updatedData.Elementos.length) {
                              updatedData.Elementos[index + 1].Tempo_inicial =
                                e.target.value;
                            }
                            setCoachCardData(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <select
                          hidden={coachCardData.Categoria === "MAS"}
                          value={elemento.Elemento}
                          onChange={(e) => {
                            const updatedData = { ...coachCardData };
                            updatedData.Elementos[index].Elemento =
                              e.target.value;
                            updatedData.Elementos[index].Grupo = "none";
                            updatedData.Elementos[index].Dificuldade_declarada =
                              [];
                            updatedData.Elementos[index].Bonus = [];
                            updatedData.Elementos[index].Total =
                              totalElemento(index);
                            updatedData.Total_declarado = totalDeclarado();
                            switch (e.target.value) {
                              case "HIB": {
                                updatedData.Elementos[index].Nota_base = 0.5;
                                break;
                              }
                              case "TRE":
                              case "TRA": {
                                updatedData.Elementos[index].Nota_base = 0;
                                break;
                              }
                              case "ACR": {
                                if (
                                  updatedData.Esquema === "DUETO TECNICO" ||
                                  updatedData.Esquema === "DUETO MISTO TECNICO"
                                ) {
                                  updatedData.Elementos[index].Nota_base = 0.1;
                                } else {
                                  updatedData.Elementos[index].Nota_base = 0.5;
                                }

                                break;
                              }
                              default: {
                                //statements;
                                break;
                              }
                            }
                            setCoachCardData(updatedData);
                          }}
                        >
                          <option value="none">Selecione um elemento</option>
                          <option key="HIB" value="HIB">
                            HÍBRIDA
                          </option>
                          <option key="TRE" value="TRE">
                            TRE
                          </option>
                          <option key="TRA" value="TRA">
                            TRANSIÇÃO
                          </option>
                          <option key="ACR" value="ACR">
                            ACROBACIA
                          </option>
                        </select>
                        <input
                          hidden={coachCardData.Categoria !== "MAS"}
                          type="text"
                          value={elemento.Elemento}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={elemento.Ordem}
                          onChange={(e) => {
                            const updatedData = { ...coachCardData };
                            updatedData.Elementos[index].Ordem = e.target.value;
                            setCoachCardData(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <select
                          value={elemento.Grupo}
                          onChange={(e) => {
                            const updatedData = { ...coachCardData };
                            updatedData.Elementos[index].Grupo = e.target.value;
                            setCoachCardData(updatedData);
                          }}
                          disabled={elemento.Elemento !== "ACR"}
                        >
                          <option value="none">Selecione um elemento</option>
                          <option key="A" value="A">
                            ACRO-A
                          </option>
                          <option key="B" value="B">
                            ACRO-B
                          </option>
                          <option key="C" value="C">
                            ACRO-C
                          </option>
                          <option key="P" value="P">
                            ACRO-P
                          </option>
                          <option key="Pair" value="Pair">
                            ACRO-Pair
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={elemento.Nota_base}
                          onChange={(e) => {
                            const updatedData = { ...coachCardData };
                            updatedData.Elementos[index].Nota_base =
                              +e.target.value;
                            setCoachCardData(updatedData);
                          }}
                          disabled
                        />
                      </td>
                      <td hidden={coachCardData.Categoria === "MAS"}>
                        {elemento.Dificuldade_declarada.map(
                          (coeficiente, coefIndex) => (
                            <div key={coefIndex}>
                              <select
                                value={coeficiente.Codigo_cc}
                                onChange={(e) => {
                                  const updatedData = { ...coachCardData };
                                  if (e.target.value === "remove") {
                                    updatedData.Elementos[
                                      index
                                    ].Dificuldade_declarada = updatedData.Elementos[
                                      index
                                    ].Dificuldade_declarada.filter(
                                      (x, i) => i !== coefIndex
                                    );
                                  } else {
                                    updatedData.Elementos[
                                      index
                                    ].Dificuldade_declarada[
                                      coefIndex
                                    ].Codigo_cc = e.target.value;
                                    updatedData.Elementos[
                                      index
                                    ].Dificuldade_declarada[coefIndex].Valor =
                                      getCoefValue(
                                        e.target.value,
                                        elemento.Elemento,
                                        updatedData.Esquema
                                      );
                                  }
                                  updatedData.Elementos[index].Total =
                                    totalElemento(index);
                                  updatedData.Total_declarado =
                                    totalDeclarado();
                                  setCoachCardData(updatedData);
                                }}
                                hidden={elemento.Elemento === "ACR"}
                              >
                                <option value="none">Selecione</option>
                                <option value="remove">Remover</option>
                                {getCoeficientData(
                                  elemento.Elemento,
                                  coachCardData.Esquema
                                ).map((item) => (
                                  <option
                                    key={item.Codigo_cc}
                                    value={item.Codigo_cc}
                                  >
                                    {item.Codigo_cc}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="text"
                                value={coeficiente.Codigo_cc}
                                hidden={elemento.Elemento !== "ACR"}
                                onChange={(e) => {
                                  const updatedData = { ...coachCardData };
                                  updatedData.Elementos[
                                    index
                                  ].Dificuldade_declarada[coefIndex].Codigo_cc =
                                    e.target.value;
                                  setCoachCardData(updatedData);
                                }}
                              />
                              <input
                                type="number"
                                value={coeficiente.Valor}
                                onChange={(e) => {
                                  const updatedData = { ...coachCardData };
                                  updatedData.Elementos[
                                    index
                                  ].Dificuldade_declarada[coefIndex].Valor =
                                    +e.target.value;
                                  updatedData.Elementos[index].Total =
                                    totalElemento(index);
                                  updatedData.Total_declarado =
                                    totalDeclarado();
                                  setCoachCardData(updatedData);
                                }}
                                disabled={elemento.Elemento !== "ACR"}
                              />
                            </div>
                          )
                        )}
                        <button
                          className="button"
                          onClick={() =>
                            addCoeficiente(index, "Dificuldade_declarada")
                          }
                          hidden={
                            coachCardData.State === "Submetido" ||
                            coachCardData.State === "Verificado" ||
                            elemento.Elemento === "TRA"
                          }
                        >
                          +
                        </button>
                      </td>
                      <td hidden={coachCardData.Categoria === "MAS"}>
                        {elemento.Bonus.map((coeficiente, coefIndex) => (
                          <div key={coefIndex}>
                            <select
                              value={coeficiente.Codigo_cc}
                              onChange={(e) => {
                                const updatedData = { ...coachCardData };
                                if (e.target.value === "remove") {
                                  updatedData.Elementos[index].Bonus =
                                    updatedData.Elementos[index].Bonus.filter(
                                      (x, i) => i !== coefIndex
                                    );
                                } else {
                                  updatedData.Elementos[index].Bonus[
                                    coefIndex
                                  ].Codigo_cc = e.target.value;
                                  updatedData.Elementos[index].Bonus[
                                    coefIndex
                                  ].Valor = bonus.filter(
                                    (x) => x.Codigo_cc === e.target.value
                                  )[0].Valor;
                                }

                                updatedData.Elementos[index].Total =
                                  totalElemento(index);
                                updatedData.Total_declarado = totalDeclarado();
                                setCoachCardData(updatedData);
                              }}
                              hidden={elemento.Elemento === "ACR"}
                            >
                              <option value="none">Selecione</option>
                              <option value="remove">Remover</option>
                              {bonus.map((item) => (
                                <option
                                  key={item.Codigo_cc}
                                  value={item.Codigo_cc}
                                >
                                  {item.Codigo_cc}
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              value={coeficiente.Codigo_cc}
                              hidden={elemento.Elemento !== "ACR"}
                              onChange={(e) => {
                                const updatedData = { ...coachCardData };
                                updatedData.Elementos[index].Bonus[
                                  coefIndex
                                ].Codigo_cc = e.target.value;
                                setCoachCardData(updatedData);
                              }}
                            />
                            <input
                              type="number"
                              value={coeficiente.Valor}
                              onChange={(e) => {
                                const updatedData = { ...coachCardData };
                                updatedData.Elementos[index].Bonus[
                                  coefIndex
                                ].Valor = +e.target.value;
                                updatedData.Elementos[index].Total =
                                  totalElemento(index);
                                updatedData.Total_declarado = totalDeclarado();
                                setCoachCardData(updatedData);
                              }}
                              disabled={elemento.Elemento !== "ACR"}
                            />
                          </div>
                        ))}
                        <button
                          className="button"
                          onClick={() => addCoeficiente(index, "Bonus")}
                          hidden={
                            coachCardData.State === "Submetido" ||
                            coachCardData.State === "Verificado" ||
                            elemento.Elemento === "TRA"
                          }
                        >
                          +
                        </button>
                      </td>
                      <td>{elemento.Total}</td>
                      <td>
                        <button
                          hidden={userInfo.role === "referee"}
                          className="button"
                          onClick={() => removeElemento(index)}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td hidden={coachCardData.Categoria === "MAS"}></td>
                  <td hidden={coachCardData.Categoria === "MAS"}></td>
                  <td>{coachCardData.Total_declarado}</td>
                  <td></td>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <button
          disabled={
            load ||
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0
          }
          hidden={
            coachCardData.State === "Submetido" ||
            coachCardData.State === "Verificado"
          }
          className={`button ${
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0 ||
            coachCardData.Atletas.length === 0
              ? "disabled-button"
              : ""
          }`}
          onClick={() => saveCoachCard("Rascunho")}
        >
          Gravar Coach Card
        </button>
        <button
          disabled={
            load ||
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0
          }
          hidden={
            coachCardData.State === "Submetido" ||
            coachCardData.State === "Verificado"
          }
          className={`button ${
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0 ||
            coachCardData.Atletas.length === 0
              ? "disabled-button"
              : ""
          }`}
          onClick={() => saveCoachCard("Submetido")}
        >
          Submeter Coach Card
        </button>
      </fieldset>
      <div>
        <button
          disabled={
            load ||
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0
          }
          hidden={userInfo.role !== "admin" && userInfo.role !== "referee"}
          className={`button ${
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0 ||
            coachCardData.Atletas.length === 0
              ? "disabled-button"
              : ""
          }`}
          onClick={() => saveCoachCard("Rascunho")}
        >
          Requisitar alterações Coach Card
        </button>
        <button
          disabled={
            load ||
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0
          }
          hidden={
            (userInfo.role !== "admin" && userInfo.role !== "referee") ||
            coachCardData.State === "Verificado"
          }
          className={`button ${
            !(
              coachCardData.Categoria &&
              coachCardData.Esquema &&
              coachCardData.Tema_esquema
            ) ||
            coachCardData.Elementos.length === 0 ||
            coachCardData.Atletas.length === 0
              ? "disabled-button"
              : ""
          }`}
          onClick={() => saveCoachCard("Verificado")}
        >
          Submeter Verificação Coach Card
        </button>
      </div>
    </PageLayout>
  );
};

export default CreateCoachCard;
