import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';
import { IUserInfo } from 'src/components/interfaces/UserInfo';

interface GlobalProviderProps {
    children: ReactNode;
}

interface IGlobalContextProps {
    userInfo: IUserInfo;
    setuserInfo: Dispatch<SetStateAction<IUserInfo>>;
    resetGlobalContext: () => void;
}

const initialUserInfo: IUserInfo = {
    role: '',
    clube: ''
};

const GlobalContext = createContext({} as IGlobalContextProps);

const GlobalProvider = ({ children }: GlobalProviderProps) => {
    const [userInfo, setuserInfo] = useState<IUserInfo>(initialUserInfo);
    const resetGlobalContext = () => {
        setuserInfo(initialUserInfo);
    };
    return (
        <GlobalContext.Provider
            value={{
                userInfo,
                setuserInfo,
                resetGlobalContext
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

const useGlobal = () => useContext(GlobalContext);

export { GlobalContext, GlobalProvider, useGlobal };
