import React, { useEffect, useState } from "react";
import { CoachCard } from "src/components/interfaces/CoachCard";
import "./RefCoachCard.css";
import { PageLayout } from "src/components/page-layout";
import { useGlobal } from "src/contexts/GlobalContext";
import html2pdf from "html2pdf.js";
const RefCoachCard: React.FC = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  useEffect(() => {
    if (_id && _id !== "") {
      fetch(`${url}/coachCard/${_id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: CoachCard) => {
          console.log(data);
          if (data) {
            setCoachCardData(data);
          }
        });
    }
  }, []);
  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();
  const [coachCardData, setCoachCardData] = useState<CoachCard>({
    _id: "",
    Competicao: "",
    Categoria: "",
    Esquema: "",
    Clube: userInfo.clube,
    Tema_esquema: "",
    Atletas: [],
    Reservas: [],
    Elementos: [],
    State: "Rascunho",
    Total_declarado: 0,
  });

  const generatePdf = async () => {
    const element = document.getElementById(_id + "");
    const options = {
      filename: `CC_${coachCardData.Clube}_${coachCardData.Categoria}_${coachCardData.Esquema}`,
      pagebreak: { mode: "css", before: ".page-break" },
      margin: 10,
      jsPDF: {
        format: "a4",
      },
    };
    await html2pdf().from(element).set(options).save();
  };
  return (
    <PageLayout>
      <div className="ref-body">
        <fieldset id={_id + ""} disabled>
          <div className="ref-form-container">
            <div className="ref-form-group">
              <label className="ref-label">Competicao:</label>
              <input
                className="ref-input-text"
                type="text"
                value={coachCardData.Competicao}
                disabled
              />
            </div>
            <div className="ref-form-group">
              <label className="ref-label">Categoria:</label>
              <input
                className="ref-input-text"
                type="text"
                value={coachCardData.Categoria}
                disabled
              />
            </div>
            <div className="ref-form-group">
              <label className="ref-label">Prova/Esquema:</label>
              <input
                className="ref-input-text"
                type="text"
                value={coachCardData.Esquema}
                disabled
              />
            </div>
            <div className="ref-form-group">
              <label className="ref-label">Clube:</label>
              <input
                className="ref-input-text"
                type="text"
                value={coachCardData.Clube}
                disabled
              />
            </div>
            <div className="ref-form-group">
              <label className="ref-label">Tema do Esquema:</label>
              <input
                className="ref-input-text"
                type="text"
                value={coachCardData.Tema_esquema}
              />
            </div>
          </div>
          <div>
            <div className="ref-form-group">
              <label className="ref-label">Atletas:</label>
              <div
                style={{
                  width: "100%",
                  padding: "5px",
                  borderRadius: "3px",
                  fontSize: "10px",
                  minHeight: "10px", // Adjust the height as needed
                  whiteSpace: "pre-wrap", // Preserve line breaks and wrap text
                }}
              >
                {coachCardData.Atletas.map((a) => a.Nome).join(", ")}
              </div>
            </div>
          </div>
          {coachCardData.Elementos.length > 0 && (
            <div>
              <h3>Elementos:</h3>
              <div className="ref-table-container">
                <table className="ref-table">
                  <thead>
                    <tr>
                      <th style={{ width: "75px" }}>Tempo</th>
                      <th style={{ width: "50px" }}>Elemento</th>
                      <th style={{ width: "40px" }}>Ordem</th>
                      <th style={{ width: "50px" }}>Grupo</th>
                      <th style={{ width: "200px" }}>Dificuldade Declarada</th>
                      <th style={{ width: "75px" }}>Bonus</th>
                      <th style={{ width: "50px" }}>DD</th>
                      <th style={{ width: "50px" }}>TC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachCardData.Elementos.map((elemento, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            className="ref-input-text"
                            type="text"
                            value={`${elemento.Tempo_inicial}-${elemento.Tempo_final}`}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            style={{ width: "50px" }}
                            type="text"
                            value={`${elemento.Elemento}`}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            style={{ width: "50px" }}
                            type="text"
                            value={`${elemento.Ordem}`}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            style={{ width: "50px" }}
                            type="text"
                            value={`${elemento.Grupo}`}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            type="text"
                            value={elemento.Dificuldade_declarada.map(
                              (a) => a.Codigo_cc
                            ).join(" ")}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            type="text"
                            value={elemento.Bonus.map((a) => a.Codigo_cc).join(
                              " "
                            )}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            type="text"
                            value={elemento.Total}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="ref-input-text"
                            type="text"
                            value=""
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </fieldset>
      </div>
      <div>
        <button className="button" onClick={() => generatePdf()}>
          Gravar Pdf
        </button>
      </div>
    </PageLayout>
  );
};

export default RefCoachCard;
