import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import { Competition } from "src/components/interfaces/Competition";
import { useGlobal } from "src/contexts/GlobalContext";
import { useHistory } from "react-router-dom";

export const CompetitionPage: React.FC = () => {
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  useEffect(() => {
    fetch(`${url}/competitions`)
      .then((response) => response.json())
      .then((competitionData: Competition[]) => {
        setCompetitions(competitionData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  function hasDatePassed(targetDateString: string): boolean {
    const currentDate = new Date();
    const targetDate = new Date(targetDateString);

    return targetDate < currentDate;
  }
  function deleteCompetition(id: string | undefined): void {
    fetch(`${url}/competitions/${id}`, { method: "DELETE" })
      .then((response) => {
        if (response.ok) {
          setCompetitions(competitions.filter((x) => x._id !== id));
        } else {
          console.error("Failed to delete resource");
        }
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  }

  function editCompetition(id: string | undefined): void {
    history.push(`/admin?id=${id}`);
  }
  function createCompetition(): void {
    history.push(`/admin`);
  }
  function createCoachCard(id: string | undefined): void {
    history.push(`/coachCard?compId=${id}`);
  }
  function createFigura(id: string | undefined): void {
    history.push(`/figura?compId=${id}`);
  }
  function getStartList(id: string | undefined): void {
    history.push(`/startList?id=${id}`);
  }

  function getResultList(id: string | undefined): void {
    history.push(`/resultList?id=${id}`);
  }
  function resultFigura(id: string | undefined): void {
    history.push(`/resultFiguras?compId=${id}`);
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title">Competições</h1>
        <br />
        <br />
        <button
          hidden={userInfo.role !== "admin"}
          className="edit-button"
          style={{ width: "15%" }}
          onClick={() => createCompetition()}
        >
          Criar Competição
        </button>
        <br />
        <br />
        <table className="coach-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Data</th>
              <th>Limite de inscrições</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {competitions.map((c, index) => (
              <tr key={c._id || index}>
                <td>{c.Nome}</td>
                <td>{c.Data}</td>
                <td>{c.Limite_inscricao}</td>
                <td>
                  <button
                    hidden={userInfo.role !== "admin"}
                    className="edit-button"
                    onClick={() => editCompetition(c._id)}
                  >
                    Editar
                  </button>
                  <button
                    hidden={!userInfo.role}
                    disabled={hasDatePassed(c.Limite_inscricao)}
                    className="edit-button"
                    onClick={() => createFigura(c._id)}
                  >
                    Criar Figuras
                  </button>
                  <button
                    hidden={!userInfo.role}
                    disabled={hasDatePassed(c.Limite_inscricao)}
                    className="edit-button"
                    onClick={() => createCoachCard(c._id)}
                  >
                    Criar CoachCard
                  </button>
                  <button
                    disabled={c.StartList.length <= 0}
                    className="edit-button"
                    onClick={() => getStartList(c._id)}
                  >
                    StartList
                  </button>
                  <button
                    hidden={userInfo.role !== "admin"}
                    disabled={c.StartList.length <= 0}
                    className="edit-button"
                    onClick={() => resultFigura(c._id)}
                  >
                    Resultados Figuras
                  </button>
                  <button
                    disabled={
                      c.Ordem.filter((o) => o.Resultado).length <= 0
                    }
                    className="edit-button"
                    onClick={() => getResultList(c._id)}
                  >
                    Resultados
                  </button>
                  <button
                    hidden={userInfo.role !== "admin"}
                    className="delete-button"
                    onClick={() => deleteCompetition(c._id)}
                  >
                    Apagar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};
