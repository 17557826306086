import { Coeficientes } from "src/components/interfaces/CoachCard";

export const bonus: Coeficientes[] = [
  { Codigo_cc: "TR", Valor: 0.15 },
  { Codigo_cc: "TR*0.5", Valor: 0.075 },
  { Codigo_cc: "TR*0.3", Valor: 0.045 },
  { Codigo_cc: "PL", Valor: 0.2 },
  { Codigo_cc: "PL*0.5", Valor: 0.1 },
  { Codigo_cc: "PL*0.3", Valor: 0.06 },
  { Codigo_cc: "SY-P", Valor: 0.1 },
  { Codigo_cc: "SY-P*0.5", Valor: 0.05 },
  { Codigo_cc: "SY-P*0.3", Valor: 0.03 },
  { Codigo_cc: "2SY-P", Valor: 0.2 },
  { Codigo_cc: "2SY-P*0.5", Valor: 0.1 },
  { Codigo_cc: "2SY-P*0.3", Valor: 0.06 },
  { Codigo_cc: "SY-F", Valor: 0.5 },
  { Codigo_cc: "SY-F*0.5", Valor: 0.25 },
  { Codigo_cc: "SY-F*0.3", Valor: 0.15 },
  { Codigo_cc: "1PC", Valor: 0.3 },
  { Codigo_cc: "2PC", Valor: 0.6 },
  { Codigo_cc: "3PC", Valor: 0.9 },
  { Codigo_cc: "4PC", Valor: 1.2 },
  { Codigo_cc: "5PC", Valor: 1.5 },
  { Codigo_cc: "6PC", Valor: 1.8 },
  { Codigo_cc: "1PC*0.5", Valor: 0.15 },
  { Codigo_cc: "2PC*0.5", Valor: 0.3 },
  { Codigo_cc: "3PC*0.5", Valor: 0.45 },
  { Codigo_cc: "4PC*0.5", Valor: 0.6 },
  { Codigo_cc: "5PC*0.5", Valor: 0.75 },
  { Codigo_cc: "6PC*0.5", Valor: 0.9 },
  { Codigo_cc: "1PC*0.3", Valor: 0.09 },
  { Codigo_cc: "2PC*0.3", Valor: 0.18 },
  { Codigo_cc: "3PC*0.3", Valor: 0.27 },
  { Codigo_cc: "4PC*0.3", Valor: 0.36 },
  { Codigo_cc: "5PC*0.3", Valor: 0.45 },
  { Codigo_cc: "6PC*0.3", Valor: 0.54 },
];

export const coefHibridas: Coeficientes[] = [
  // T-values
  { Codigo_cc: "T1", Valor: 0.15 },
  { Codigo_cc: "T2", Valor: 0.3 },
  { Codigo_cc: "T3", Valor: 0.35 },
  { Codigo_cc: "T4", Valor: 0.4 },
  { Codigo_cc: "T5", Valor: 0.45 },
  { Codigo_cc: "T6", Valor: 0.5 },
  { Codigo_cc: "T7", Valor: 0.55 },
  { Codigo_cc: "T8", Valor: 0.6 },
  { Codigo_cc: "T9", Valor: 0.65 },
  { Codigo_cc: "T1*0.5", Valor: 0.075 },
  { Codigo_cc: "T2*0.5", Valor: 0.15 },
  { Codigo_cc: "T3*0.5", Valor: 0.175 },
  { Codigo_cc: "T4*0.5", Valor: 0.2 },
  { Codigo_cc: "T5*0.5", Valor: 0.225 },
  { Codigo_cc: "T6*0.5", Valor: 0.25 },
  { Codigo_cc: "T7*0.5", Valor: 0.275 },
  { Codigo_cc: "T8*0.5", Valor: 0.3 },
  { Codigo_cc: "T9*0.5", Valor: 0.325 },
  { Codigo_cc: "T1*0.3", Valor: 0.045 },
  { Codigo_cc: "T2*0.3", Valor: 0.09 },
  { Codigo_cc: "T3*0.3", Valor: 0.105 },
  { Codigo_cc: "T4*0.3", Valor: 0.12 },
  { Codigo_cc: "T5*0.3", Valor: 0.135 },
  { Codigo_cc: "T6*0.3", Valor: 0.15 },
  { Codigo_cc: "T7*0.3", Valor: 0.165 },
  { Codigo_cc: "T8*0.3", Valor: 0.18 },
  { Codigo_cc: "T9*0.3", Valor: 0.195 },

  // R-values
  { Codigo_cc: "R1", Valor: 0.15 },
  { Codigo_cc: "R2", Valor: 0.35 },
  { Codigo_cc: "R3", Valor: 0.45 },
  { Codigo_cc: "R4", Valor: 0.55 },
  { Codigo_cc: "R5", Valor: 0.6 },
  { Codigo_cc: "R6", Valor: 0.65 },
  { Codigo_cc: "R7", Valor: 0.7 },
  { Codigo_cc: "R8", Valor: 0.75 },
  { Codigo_cc: "R9", Valor: 0.8 },
  { Codigo_cc: "R1*0.5", Valor: 0.075 },
  { Codigo_cc: "R2*0.5", Valor: 0.175 },
  { Codigo_cc: "R3*0.5", Valor: 0.225 },
  { Codigo_cc: "R4*0.5", Valor: 0.275 },
  { Codigo_cc: "R5*0.5", Valor: 0.3 },
  { Codigo_cc: "R6*0.5", Valor: 0.325 },
  { Codigo_cc: "R7*0.5", Valor: 0.35 },
  { Codigo_cc: "R8*0.5", Valor: 0.375 },
  { Codigo_cc: "R9*0.5", Valor: 0.4 },
  { Codigo_cc: "R1*0.3", Valor: 0.045 },
  { Codigo_cc: "R2*0.3", Valor: 0.105 },
  { Codigo_cc: "R3*0.3", Valor: 0.135 },
  { Codigo_cc: "R4*0.3", Valor: 0.165 },
  { Codigo_cc: "R5*0.3", Valor: 0.18 },
  { Codigo_cc: "R6*0.3", Valor: 0.195 },
  { Codigo_cc: "R7*0.3", Valor: 0.21 },
  { Codigo_cc: "R8*0.3", Valor: 0.225 },
  { Codigo_cc: "R9*0.3", Valor: 0.24 },

  // F-values
  { Codigo_cc: "F1", Valor: 0.05 },
  { Codigo_cc: "F2", Valor: 0.1 },
  { Codigo_cc: "F3", Valor: 0.15 },
  { Codigo_cc: "F4", Valor: 0.2 },
  { Codigo_cc: "F5", Valor: 0.25 },
  { Codigo_cc: "F6", Valor: 0.3 },
  { Codigo_cc: "F1*0.5", Valor: 0.025 },
  { Codigo_cc: "F2*0.5", Valor: 0.05 },
  { Codigo_cc: "F3*0.5", Valor: 0.075 },
  { Codigo_cc: "F4*0.5", Valor: 0.1 },
  { Codigo_cc: "F5*0.5", Valor: 0.125 },
  { Codigo_cc: "F6*0.5", Valor: 0.15 },
  { Codigo_cc: "F1*0.3", Valor: 0.015 },
  { Codigo_cc: "F2*0.3", Valor: 0.03 },
  { Codigo_cc: "F3*0.3", Valor: 0.045 },
  { Codigo_cc: "F4*0.3", Valor: 0.06 },
  { Codigo_cc: "F5*0.3", Valor: 0.075 },
  { Codigo_cc: "F6*0.3", Valor: 0.09 },

  // AW-values
  { Codigo_cc: "AW1", Valor: 0.05 },
  { Codigo_cc: "AW2", Valor: 0.15 },
  { Codigo_cc: "AW3", Valor: 0.3 },
  { Codigo_cc: "AW4", Valor: 0.45 },
  { Codigo_cc: "AW5", Valor: 0.5 },
  { Codigo_cc: "AW6", Valor: 0.6 },
  { Codigo_cc: "AW7", Valor: 0.65 },
  { Codigo_cc: "AW1*0.5", Valor: 0.025 },
  { Codigo_cc: "AW2*0.5", Valor: 0.075 },
  { Codigo_cc: "AW3*0.5", Valor: 0.15 },
  { Codigo_cc: "AW4*0.5", Valor: 0.225 },
  { Codigo_cc: "AW5*0.5", Valor: 0.25 },
  { Codigo_cc: "AW6*0.5", Valor: 0.3 },
  { Codigo_cc: "AW7*0.5", Valor: 0.325 },
  { Codigo_cc: "AW1*0.3", Valor: 0.015 },
  { Codigo_cc: "AW2*0.3", Valor: 0.045 },
  { Codigo_cc: "AW3*0.3", Valor: 0.09 },
  { Codigo_cc: "AW4*0.3", Valor: 0.135 },
  { Codigo_cc: "AW5*0.3", Valor: 0.15 },
  { Codigo_cc: "AW6*0.3", Valor: 0.18 },
  { Codigo_cc: "AW7*0.3", Valor: 0.195 },

  // C-values
  { Codigo_cc: "C1", Valor: 0.05 },
  { Codigo_cc: "C2", Valor: 0.2 },
  { Codigo_cc: "C3", Valor: 0.35 },
  { Codigo_cc: "C4", Valor: 0.45 },
  { Codigo_cc: "C5", Valor: 0.5 },
  { Codigo_cc: "C6", Valor: 0.55 },
  { Codigo_cc: "C1+", Valor: 0.150 },
  { Codigo_cc: "C2+", Valor: 0.300},
  { Codigo_cc: "C3+", Valor: 0.450 },
  { Codigo_cc: "C4+", Valor: 0.550 },
  { Codigo_cc: "C5+", Valor: 0.600},
  { Codigo_cc: "C6+", Valor: 0.650 },
  { Codigo_cc: "C1*0.5", Valor: 0.025 },
  { Codigo_cc: "C2*0.5", Valor: 0.1 },
  { Codigo_cc: "C3*0.5", Valor: 0.175 },
  { Codigo_cc: "C4*0.5", Valor: 0.225 },
  { Codigo_cc: "C5*0.5", Valor: 0.25 },
  { Codigo_cc: "C6*0.5", Valor: 0.275 },
  { Codigo_cc: "C1*0.3", Valor: 0.015 },
  { Codigo_cc: "C2*0.3", Valor: 0.06 },
  { Codigo_cc: "C3*0.3", Valor: 0.105 },
  { Codigo_cc: "C4*0.3", Valor: 0.135 },
  { Codigo_cc: "C5*0.3", Valor: 0.15 },
  { Codigo_cc: "C6*0.3", Valor: 0.165 },
];

export const soloTRE: Coeficientes[] = [
  { Codigo_cc: "S-TRE1a", Valor: 2.7 },
  { Codigo_cc: "S-TRE1b", Valor: 2.1 },
  { Codigo_cc: "S-TRE2a", Valor: 3.0 },
  { Codigo_cc: "S-TRE2b", Valor: 2.7 },
  { Codigo_cc: "S-TRE3", Valor: 3.2 },
  { Codigo_cc: "S-TRE4a", Valor: 2.9 },
  { Codigo_cc: "S-TRE4b", Valor: 2.6 },
  { Codigo_cc: "S-TRE5a", Valor: 2.4 },
  { Codigo_cc: "S-TRE5b", Valor: 2.1 },
];

export const duetoTRE: Coeficientes[] = [
  { Codigo_cc: "D-TRE1a", Valor: 3.0 },
  { Codigo_cc: "D-TRE1b", Valor: 2.5 },
  { Codigo_cc: "D-TRE2a", Valor: 2.8 },
  { Codigo_cc: "D-TRE2b", Valor: 2.4 },
  { Codigo_cc: "D-TRE3a", Valor: 2.9 },
  { Codigo_cc: "D-TRE3b", Valor: 2.6 },
  { Codigo_cc: "D-TRE4a", Valor: 3.2 },
  { Codigo_cc: "D-TRE4b", Valor: 2.7 },
  { Codigo_cc: "D-TRE5a", Valor: 2.3 },
  { Codigo_cc: "D-TRE5b", Valor: 2.1 },
];

export const duetoMTRE: Coeficientes[] = [
  { Codigo_cc: "M-TRE1a", Valor: 2.70 },
  { Codigo_cc: "M-TRE1b", Valor: 2.50 },
  { Codigo_cc: "M-TRE2a", Valor: 2.40 },
  { Codigo_cc: "M-TRE2b", Valor: 2.20 },
  { Codigo_cc: "M-TRE3",  Valor: 3.30 },
  { Codigo_cc: "M-TRE4a", Valor: 3.00 },
  { Codigo_cc: "M-TRE4b", Valor: 2.60 },
  { Codigo_cc: "M-TRE5a", Valor: 2.40 },
  { Codigo_cc: "M-TRE5b", Valor: 2.10 },
];
export const equipaTRE: Coeficientes[] = [
  { Codigo_cc: "T-TRE1a", Valor: 2.5 },
  { Codigo_cc: "T-TRE1b", Valor: 2.3 },
  { Codigo_cc: "T-TRE2a", Valor: 2.6 },
  { Codigo_cc: "T-TRE2b", Valor: 2.3 },
  { Codigo_cc: "T-TRE3a", Valor: 2.6 },
  { Codigo_cc: "T-TRE3b", Valor: 2.3 },
  { Codigo_cc: "T-TRE4", Valor: 2.9 },
  { Codigo_cc: "T-TRE5a", Valor: 2.4 },
  { Codigo_cc: "T-TRE5b", Valor: 2.1 },
];
