import React from "react";

export const HeroBanner: React.FC = () => {
  const logo = "https://www.annp.pt/themes/custom/annp_barrio/logo.png";

  return (
    <div className="hero-banner hero-banner--pink-yellow">
        <img className="hero-banner__image" src={logo} alt="React logo" />
      <h1 className="hero-banner__headline">Bem vindo à Artistic ANNP</h1>
      <p className="hero-banner__description">
        Para ter acesso à criação de Coach Cards envie email para info@annp.pt
      </p>
    </div>
  );
};
